<template>
  <div>
    <!-- 卡片视图区域 -->
    <el-card style="min-width: 1000px">
      <!-- 添加角色按钮区域 -->
      <el-row>
        <el-col>
          <el-button type="primary" @click="showRoleDialogClick(1, {})"
            >添加角色</el-button
          >
        </el-col>
      </el-row>

      <el-table :data="roleList" border stripe>
        <el-table-column type="expand">
          <template #default="scope">
            <el-row
              :class="['bdbottom', i1 === 0 ? 'bdtop' : '', 'vcenter']"
              v-for="(item1, i1) in scope.row.children"
              :key="item1.id"
            >
              <!-- 渲染一级权限 -->
              <el-col :span="5">
                <el-tag
                  closable
                  @close="removeRightById(scope.row, item1.id)"
                  >{{ item1.name }}</el-tag
                >
                <i class="el-icon-caret-right"></i>
              </el-col>
              <!-- 渲染二级和三级权限 -->
              <el-col :span="19">
                <!-- 通过 for 循环 嵌套渲染二级权限 -->
                <el-row
                  :class="[i2 === 0 ? '' : 'bdtop', 'vcenter']"
                  v-for="(item2, i2) in item1.children"
                  :key="item2.id"
                >
                  <el-col :span="6">
                    <el-tag
                      type="success"
                      closable
                      @close="removeRightById(scope.row, item2.id)"
                      >{{ item2.name }}</el-tag
                    >
                    <i class="el-icon-caret-right"></i>
                  </el-col>
                  <el-col :span="18">
                    <el-tag
                      type="warning"
                      v-for="item3 in item2.children"
                      :key="item3.id"
                      closable
                      @close="removeRightById(scope.row, item3.id)"
                      >{{ item3.name }}</el-tag
                    >
                  </el-col>
                </el-row>
              </el-col>
            </el-row>

            <!-- <pre>
                {{scope.row}}
              </pre>-->
          </template>
        </el-table-column>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="角色名称"
          prop="roleName"
          width="100"
        ></el-table-column>
        <el-table-column label="角色描述" prop="roleDesc"></el-table-column>
        <el-table-column label="角色状态" width="100">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status == '0'">启用</el-tag>
            <el-tag type="warning" v-else>停用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" prop="roleDesc">
          <template #default="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              @click="showRoleDialogClick(2, scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="removeRole(scope.row.roleId)"
              >删除</el-button
            >
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-setting"
              @click="showTreeDialogClick(scope.row)"
              >分配权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加分类的对话框 -->
    <el-dialog title="编辑角色" :visible.sync="isRoleDialog">
      <el-form
        :model="roleFrom"
        ref="updatemenuRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称">
          <el-input v-model="roleFrom.roleName"></el-input>
        </el-form-item>

        <el-form-item label="角色code">
          <el-input v-model="roleFrom.roleKey"></el-input>
        </el-form-item>
        <el-form-item label="角色状态">
          <template #default>
            <el-switch
              v-model="roleFrom.status"
              active-color="#13ce66"
              active-value="1"
              inactive-value="0"
              @change="switchChange"
            ></el-switch>
            {{ roleFrom.status == 1 ? "启用" : "停用" }}
          </template>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm()">提交</el-button>
          <el-button @click="isRoleDialog = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 分配权限的对话框 -->
    <el-dialog title="分配权限" :visible.sync="isTreeDialog">
      <!-- 树形控件 -->
      <el-tree
        :default-checked-keys="defKeys"
        :data="permissionList"
        :props="treeProps"
        node-key="id"
        default-expand-all
        show-checkbox
        ref="updatetreeRef"
        @check="change"
      ></el-tree>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isTreeDialog = false">取 消</el-button>
          <el-button type="primary" @click="submitTreeClick">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "后台项目Index",

  data() {
    return {
      isRoleDialog: false,
      isTreeDialog: false,
      roleList: [],
      permissionList: [],
      roleFrom: {
        roleKey: "",
        roleName: "",
        status: "1",
      },
      treeProps: {
        label: "name",
        children: "children",
        id: "123",
      },
      roleId: "",
      defKeys: [],
      addType: 1,
      menuIds: "",
    };
  },

  mounted() {
    this.getRoleList();
  },

  methods: {
    change(item, node) {
      this.menuIds = [...node.checkedKeys, ...node.halfCheckedKeys];

      // console.log(list);
    },
    //获取角色列表
    async getRoleList() {
      const { data } = await this.$API.getRoleList();
      this.roleList = data;
    },

    //添加角色弹框
    showRoleDialogClick(Num, Item) {
      this.addType = Num;
      if (Num == 1) {
        this.roleFrom = { roleKey: "", roleName: "", status: "1" };
      } else {
        this.roleFrom = Item;
      }

      this.isRoleDialog = true;
    },
    // 添加角色
    async submitForm() {
      this.$refs.updatemenuRef.validate(async (valid) => {
        if (!valid) return;
        if (this.addType == 1) return this.addRoleList();
        this.updateRole();
      });
    },
    async addRoleList() {
      const res = await this.$API.addRoleList(this.roleFrom);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success("添加成功");
      this.getRoleList();
      this.isRoleDialog = false;
    },
    async updateRole() {
      const res = await this.$API.updateRole(this.roleFrom);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success("修改成功");
      this.getRoleList();
      this.isRoleDialog = false;
    },

    //分配权限弹框
    showTreeDialogClick(ITEM) {
      this.roleId = ITEM.roleId;
      this.permissionListevn(ITEM);
      //
    },
    //获取权限列表
    async permissionListevn(ITEM) {
      const { data, status } = await this.$API.permissionList();
      if (status == 200) {
        this.permissionList = data;
        this.isTreeDialog = true;
        this.defKeys = [];
        this.getLeafKeys(ITEM, this.defKeys);
      }
    },
    getLeafKeys(node, arr) {
      if (!node.children) {
        return arr.push(node.id);
      }
      node.children.forEach((item) => this.getLeafKeys(item, arr));
    },
    //分配权限
    async submitTreeClick() {
      const menuIds = this.menuIds;
      const params = {
        roleId: this.roleId,
        menuIds: menuIds.join(","),
      };
      const res = await this.$API.saveRoleMenu(params);
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success(res.data);
      this.getRoleList();
      this.isTreeDialog = false;
    },

    //删除角色
    async removeRole(id) {
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(async (err) => err);
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }

      const res = await this.$API.removeRole({ roleId: id });
      if (res.status !== 200) return this.$message.error(res.msg);
      this.$message.success("删除成功");
      this.getRoleList();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-tag {
  margin: 7px;
}

.bdtop {
  border-top: 1px solid #eee;
}

.bdbottom {
  border-bottom: 1px solid #eee;
}

.vcenter {
  display: flex;
  align-items: center;
}
</style>
